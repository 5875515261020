
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  position: relative
  margin: 0
  width: 100%
  overflow: auto
  box-sizing: border-box
  height: 40vH
  +bpw(lg)
    height: 30vH
  display: flex
  flex-direction: column
  justify-content: center

  &.white
    background-color: white
  &.grey
    background-color: #efefea
  &.green
    background-color: $color-green
  &.lila
    background-color: $color-lila
  &.yellow
    background-color: $color-yellow
  &.red
    background-color: $color-red
  &.bronze
    background-color: $color-bronze
  &.beige
    background-color: $color-beige

.inner
  overflow: hidden

section
  max-width: 960px
  margin: 0 auto
  padding: 0 32px
  +bpw(lg)
    padding: 0 64px
  text-align: center

  h2
    color: black
    text-transform: uppercase
    font-size: 16px
    +bpw(lg)
      font-size: 24px
  p
    font-size: 0.9em
    +bpw(lg)
      font-size: 1.1em
    margin: 32px 0

.action
  margin-top: 32px
  padding-bottom: 16px

section::v-deep
  strong
    display: inline-block
    margin-top: 16px
    text-transform: uppercase
    font-family: $font-main

