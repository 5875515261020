
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  position: relative
  display: flex
  margin: 0
  width: 100%
  min-height: 100vH
  overflow: auto
  box-sizing: border-box
  background-position: bottom left
  background-repeat: no-repeat
  background-size: 50%
  background-image: url('../../assets/background-yellow-dots-left-bottom-white.jpg')
  background-color: white

.inner
  overflow: auto
  flex: 1
  display: flex

.center
  width: 100%
  max-width: 320px
  +bpw(xl)
    max-width: 470px

.developer
  margin: 0 45px
  max-width: 760px

.partner
  margin-top: 64px

.text
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  position: relative
  +bpw(lg)
    border-radius: 8px
    text-align: left
    height: 100%

  p
    color: $color-grey-dark
  p.large
    +bpw(xl)
      font-size: 22px
      line-height: 32px
  &.grey
    background-color: $color-grey-warm
    p
      color: $color-bronze-dark

.companybox
  display: inline-block
  margin-bottom: 16px
  +bpw(lg)
    margin-bottom: 0
    margin-right: 16px
  img
    width: auto
    height: 100%
    max-height: 40px
    margin: 16px auto
    display: block
    +bpw(lg)
      margin: 0
    +bpw(xl)
      max-height: 50px
      margin-bottom: 20px
    +bpw(hg)
      max-height: 64px

.adressbox
  background-color: white
  border-radius: 8px
  padding: 24px 32px
  margin-top: 8px
  text-align: left
  h2
    margin: 0
    margin-bottom: 16px
  a
    color: black
    text-decoration: none
    transition: all 0.75s ease-out
    span:first-child
      margin-right: 16px
    &:hover
      color: $color-red

.colorbox
  position: absolute
  &.lila
    height: 94px
    bottom: 0
    left: 0
    right: 0
    border-radius: 8px
    background-color: $color-grey-warm
  &.yellow
    height: 44px
    width: 44px
    +bpw(lg)
      height: 64px
      width: 64px
    bottom: 102px
    left: 0
    border-radius: 8px
    background-color: $color-beige-light

.action
  margin-top: 32px
  padding-bottom: 16px

.formbutton
  margin-top: 32px

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.adressbox, h1, p, .action
  transition: all 0.3s ease-in-out 0.75s
  transform: translateY(50px)
  opacity: 0
  .animate &
    transition: all 0.75s ease-in-out
    opacity: 1
    transform: translateX(0%)
p
  .animate &
    transition: all 0.7s ease-out 0.25s

.adressbox
  .animate &
    transition: all 0.7s ease-out 0.45s

.action
  transform: translateY(10px)
  .animate &
    transition: all 0.7s ease-out 0.75s
