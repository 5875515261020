
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  display: flex
  position: relative
  margin: 0
  width: 100%
  min-height: 100vh
  box-sizing: border-box
  background-color: white
  overflow: auto
  &.left-right
    +bpw(lg)
      padding-left: 32px
  &.right-left
    padding-top: 112px
    padding-bottom: 32px
    +bpw(lg)
      padding-top: 0px
      padding-bottom: 0px
      padding-right: 32px
  &.lila
    background-color: $color-lila
    .topline
      background-color: $color-yellow
    h1, p
      color: white
  &.green
    background-color: $color-green
  &.red
    background-color: $color-red
  &.yellow
    background-color: $color-yellow
  &.bronze
    background-color: $color-bronze
  &.beige
    background-color: $color-beige
  &.grey
    background-color: $color-grey-light
  &.dotted-yellow
    background-position: top right
    background-repeat: no-repeat
    background-image: url('../../assets/background-yellow-dots.gif')
    background-size: 60%
    +bpw(md)
      background-size: 45%
    +bpw(lg)
      background-size: 25%

  p::v-deep
    margin-bottom: 16px
    strong
      font-family: $font-main
      text-transform: uppercase

.inner
  overflow: hidden
  flex: 1
  display: flex
  flex-direction: column
  +bpw(lg)
    flex-direction: row
    align-items: stretch

.center
  margin: 10px 20px
  +bpw(md)
    margin: 10px 80px
  +bpw(lg)
    max-width: 400px
    width: 100%

.left
  width: 100%
  height: 30vH
  margin-top: 32px
  +bpw(md)
    height: 50%
    margin-top: 74px
  +bpw(lg)
    width: 50%
    height: 100%
    margin-right: 8px
    margin-top: 0px
    vertical-align: top
    border-top-right-radius: 8px
    border-bottom-right-radius: 8px
    padding: 0
  background-position: 50% 50%
  background-repeat: no-repeat
  background-size: contain

.right
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  flex: 0.8
  +bpw(lg)
    width: 50%
    height: 100%
    text-align: left
    flex: unset


.action
  margin-top: 32px

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1, p, .action
  transition: all 0.3s ease-in-out 0.75s
  transform: translateY(50px)
  opacity: 0
  .animate &
    transition: all 0.75s ease-in-out
    opacity: 1
    transform: translateX(0%)

.left
  background-position: 51% 50%
  transition: all 0.7s ease-in-out
  .animate &
    background-position: 50% 50%
p
  .animate &
    transition: all 0.7s ease-out 0.25s

.action
  transform: translateY(10px)
  .animate &
    transition: all 0.7s ease-out 0.75s
