
@import '../../styles/variables'
@import '../../lib/core/sass/bp'
.bubble
  position: absolute
  width: 260px
  height: 100px
  right: 50%
  top: 180px
  overflow: hidden
  background-color: #a6cc3870
  z-index: 1
  display: flex
  align-items: center
  justify-content: center
  transform: translateX(50%)
  +bpw(md)
    width: 180px
    height: 180px
    border-radius: 180px
    top: 80px
    right: 0px
    transform: translateX(80%) rotate(7deg)
  +bpw(xl)
    right: 15%
    transform: rotate(7deg)
  +bpw(xxl)
    top: 0px
    right: 25%
  +bpw(hg)
    top: 0px
    right: 20%
  .outline
    position: absolute
    left: 5px
    top: 5px
    right: 5px
    bottom: 5px
    +bpw(md)
      border-radius: 180px
    background-color: $color-green
  span
    text-align: center
    width: 100%
    +bpw(md)
      width: 85%
    &::v-deep
      p
        color: black
        font-family: $font-secondary
      strong, bold
        font-family: $font-main
        +bpw(md)
          font-size: 1.1em
        color: black
        text-transform: uppercase

.blend
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(#1c1c1c, .40)
  z-index: 2

.vimeo-wrapper
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  pointer-events: none
  overflow: hidden

iframe
  width: 100vw
  height: 56.25vw
  min-height: 100vh
  min-width: 177.77vh
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.content
  position: absolute
  z-index: 5
  top: 50%
  transform: translateY(-120px)
  left: 32px
  right: 32px
  text-align: center
  box-sizing: border-box
  +bpw(md)
    text-align: left
    left: 15%
    top: 50%
    width: 50%
    padding: 0
    transform: translateY(-50%)
  +bpw(xl)
    left: 15%
    top: 50%
    width: 50%
    padding: 0 15% 0 0

article
  position: relative
  display: block
  margin: 0
  width: 100%
  height: 100vH
  background-color: white
  overflow: auto
  box-sizing: border-box
  &.isboxed
    height: auto
    padding-bottom: 32px
    +bpw(lg)
      padding-bottom: 0px
      height: 100vH

.inner
  overflow: hidden
  height: 100%
  display: block

.claim
  // background-color: white
  padding: 15px 15px 10px 15px
  margin-top: -30px
  display: inline-block
  p
    color: white
  +bpw(md)
    padding: 50px 50px 40px 50px
    margin-top: -50px
  +bpw(lg)
    display: none

.copy
  display: none
  +bpw(lg)
    background-color: white
    padding: 50px 30px 30px
    margin-left: -30px
    margin-top: -50px
    display: block
    color: black
    p
      margin-bottom: 16px
    strong
      font-weight: bold
      font-style: normal

.isboxed .swiper-wrapper
  height: auto
  +bpw(lg)
    top: 50%
    transform: translate3d(0px, -50%, 0)

.isboxed .swiper-slide
  box-sizing: border-box
  display: flex
  align-content: center
  padding: 0px
  flex-direction: column
  background-color: white
  position: relative
  +bpw(md)
    padding: 64px 0px
  +bpw(xl)
    padding: 64px
  .text
    margin: 0 auto
    text-align: center
    visibility: hidden
    p
      color: black
      margin-bottom: 24px
    .copy
      display: block
    max-width: 50%
    +bpw(lg)
      margin: 0
      text-align: left
      position: absolute
      left: 10%
      bottom: 20%
      max-width: 35%

.isboxed .swiper-slide.swiper-slide-active
  .text
    visibility: visible

.cw-section-media-slider
  .swiper-pagination,
  .swiper-pagination-clickable,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets
    width: auto !important
    left: 50% !important
    transform: translateX(-50%)
    bottom: 100px !important
    +bpw(md)
      bottom: 20px !important

.cw-section-media-slider.isboxed
  .swiper-pagination
    visibility: hidden
    +bpw(md)
      visibility: visible

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1, p
  transition: all 0.7s ease-in-out
  transform: translateY(50%)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0%)

p
  transform: translateY(50%)
  transition: all 0.7s ease-out 0.15s

