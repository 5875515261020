
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  display: flex
  position: relative
  margin: 0
  width: 100%
  min-height: 100vh
  box-sizing: border-box
  background-color: white
  overflow: auto
  &.left-right
    +bpw(lg)
      padding-left: 32px

.inner
  overflow: hidden
  flex: 1
  display: flex
  flex-direction: column
  +bpw(lg)
    flex-direction: row
    align-items: stretch

.center
  margin: 10px 20px
  +bpw(lg)
    width: 100%
  .images
    margin: 0 auto
    max-width: 80%
  .copy
    margin: 0 auto
    max-width: 400px
    text-align: left

.left, .right
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  flex: 1
  +bpw(lg)
    width: 50%
    height: 100%
.action
  margin-top: 32px

.office, .retail
  h1
    span
      background-color: $color-yellow
      color: #fff
      display: inline
      line-height: 0px
      padding: 0 1rem
      box-decoration-break: clone
  .copy
    p::v-deep
      margin-bottom: 16px
      strong
        text-transform: uppercase
        font-family: $font-main

.office
  background-position: bottom left
  background-repeat: no-repeat
  background-size: 40%
  background-image: url('../../assets/background-lila-dots.gif')
  h1 span
    background-color: $color-lila
  .copy
    margin-bottom: 64px
    +bpw(lg)
      transform: translateX(60px)

.retail
  background-position: center right
  background-repeat: no-repeat
  background-size: 80%
  background-image: url('../../assets/background-green-dots.gif')
  h1 span
    background-color: $color-green
  .flying
    visibility: hidden
    position: absolute
    top: 0px
    right: 0
    width: 300px
    height: 300px
    transform: translateX(50%) translateY(-50%)
    background-position: center
    background-repeat: no-repeat
    background-size: 80%
    background-image: url('../../assets/flying-plant.png')
    +bpw(lg)
      visibility: visible
      top: 124px
  .images
    position: relative
    display: flex
    margin-bottom: 64px
    justify-content: space-between
    .cw-picture
      display: flex
      width: 45%
    .cw-picture:nth-child(3)
      +bpw(lg)
        margin-top: -64px
  .copy
    +bpw(lg)
      height: 70%
      transform: translateX(-30px)

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1, p, .action
  transition: all 0.3s ease-in-out 0.75s
  transform: translateY(50px)
  opacity: 0
  .animate &
    transition: all 0.75s ease-in-out
    opacity: 1
    transform: translateX(0%)

p
  .animate &
    transition: all 0.7s ease-out 0.25s

.action
  transform: translateY(10px)
  .animate &
    transition: all 0.7s ease-out 0.75s
