//--------------------------------------------------------------------------
// Import some variables
//--------------------------------------------------------------------------

@import "./src/config/style.json"

//--------------------------------------------------------------------------
// Typo
//--------------------------------------------------------------------------

$font-main: 'brandon-grotesque', sans-serif
$font-secondary: 'Lora', serif
$font-monospace: monospace

//--------------------------------------------------------------------------
// Colors
//--------------------------------------------------------------------------

$color-red: map-get($colors, red)
$color-green: map-get($colors, green)
$color-yellow: map-get($colors, yellow)
$color-lila: map-get($colors, lila)
$color-grey: map-get($colors, grey)
$color-grey-dark: map-get($colors, grey-dark)
$color-grey-light: map-get($colors, grey-light)

$color-grey-warm: map-get($colors, grey-warm)
$color-grey-warm-dark: map-get($colors, grey-warm-dark)
$color-bronze: map-get($colors, bronze)
$color-bronze-light: map-get($colors, bronze-light)
$color-bronze-dark: map-get($colors, bronze-dark)
$color-beige: map-get($colors, beige)
$color-beige-light: map-get($colors, beige-light)

$color-copy: #000000

$body-font-family: 'brandon-grotesque', sans-serif

