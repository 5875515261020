
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  position: relative
  display: block
  margin: 0
  width: 100%
  background-color: transparent
  overflow: auto
  box-sizing: border-box
  +bpw(lg)
    height: 100vH

.inner
  overflow: hidden
  height: 100%
  display: block
  &.boxed
    flex: 1
    display: flex
    flex-direction: column
    +bpw(lg)
      flex-direction: row
      align-items: stretch

  &.white
    background-color: white

  &.grey
    background-color: #efefea

  &.white-dotted-yellow
    background-position: bottom left
    background-repeat: no-repeat
    background-size: 80%
    background-image: url('../../assets/background-yellow-dots-left-bottom-white.jpg')
    background-color: white

  &.white-dotted-yellow-right
    background-position: top right
    background-repeat: no-repeat
    background-size: 50%
    background-image: url('../../assets/background-yellow-dots.gif')
    background-color: white
    +bpw(lg)
      background-size: 25%

  &.grey-dotted-yellow
    background-position: bottom left
    background-repeat: no-repeat
    background-size: 50%
    background-image: url('../../assets/background-yellow-dots-left-bottom-grey.jpg')
    background-color: #efefea

  &.grey-dotted-yellow-center-right
    background-position: top right
    background-repeat: no-repeat
    background-size: 35%
    background-image: url('../../assets/background-yellow-dots-right-center-grey.jpg')
    background-color: #efefea

.right, .left
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  flex: 1
  +bpw(lg)
    width: 50%
    height: 100%
    padding: 0 54px
    text-align: left
    flex: unset
  +bpw(xl)
    padding: 0 105px
  +bpw(hg)
    padding: 0 155px

.right
  .center
    width: 100%

.left .center
  max-width: 400px
  margin: 10px 20px 30px 20px
  +bpw(md)
    max-width: 80%
  +bpw(lg)
    margin: 10px 20px
    max-width: 60%

.slider-nav
  position: absolute
  top: 54px
  width: 100%
  padding-top: 69.4%

.boxed .arrange-center
  width: 100%
  display: flex
  flex: 1
  height: 100%
  margin-bottom: 16px
  +bpw(lg)
    height: 100%
    text-align: left
    flex: unset
  .center
    width: 100%
    box-sizing: border-box
    padding-top: 64px
    +bpw(lg)
      padding: 115px
  .slider-nav
    padding-top: 0%
    height: 100%

