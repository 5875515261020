
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  position: relative
  margin: 0
  width: 100%
  overflow: auto
  box-sizing: border-box
  height: 100vH
  display: flex
  flex-direction: column
  justify-content: center
  background-color: white

article::v-deep
  .v-application, .v-application--wrap
    font-family: $font-main
    display: block
    min-height: auto
  .theme--light.v-application
    background-color: transparent
  .container
    box-sizing: border-box

.inner
  background-color: white
  overflow: auto
  display: flex
  align-items: center
  flex-direction: column

.center
  max-width: 400px
  border-radius: 8px
  padding: 16px 32px
  margin-top: 120px
  margin-bottom: 80px
  h1, p
    color: black
  +bpw(md)
    max-width: 600px
  +bpw(lg)
    margin-top: 210px
    max-width: 840px
  +bpw(xl)
    padding-top: 48px

.form
  display: flex
  flex-direction: row

.form-content
  +bpw(xl)
    margin-top: 32px

.fields
  padding-top: 14px
  width: 100%
  +bpw(lg)
    width: 60%

.steps
  display: none
  +bpw(lg)
    display: block
    width: 40%
    padding-top: 26px

.step
  display: block
  text-align: right
  line-height: 40px
  margin-bottom: 64px
  .label
    display: inline-block
    font-family: $font-main
    font-weight: bold
  .counter
    position: relative
    font-family: $font-main
    font-weight: bold
    margin-left: 16px
    margin-right: 32px
    text-align: center
    display: inline-block
    width: 40px
    border-radius: 8px
    color: white
    background-color: $color-grey-warm
    transition: all 0.45s ease-out
    &:after
      content: ''
      width: 2px
      background-color: $color-grey-warm
      height: 64px
      position: absolute
      bottom: 0
      left: 50%
      transform: translateY(100%)
  &:last-child
    .counter
      &:after
        content: none

.step.active
  .counter
    background-color: $color-green
    &:after
      background-color: $color-green

.text
  text-align: center
  h1
    margin-bottom: 24px
  p
    font-size: 14px
    padding: 0 32px
  +bpw(lg)
    p
      font-size: 18px

.actions
  text-align: right
  padding-bottom: 16px
  button
    margin-left: 16px

.legal
  padding: 0 16px
  p, a
    font-family: $font-main
  a
    display: inline

.ending
  background-color: white
  padding: 16px
  border-radius: 8px
  &.is-success
    h2, p
      color: $color-green
  &.is-error
    h2, p
      color: $color-red
  h2
    margin-bottom: 12px
