
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  position: relative
  display: block
  margin: 0
  width: 100%
  background-color: transparent
  overflow: auto
  box-sizing: border-box
  overflow: visible
  +bpw(lg)
    height: 100vH


.inner
  overflow: hidden
  height: 100%
  display: flex
  flex-direction: column
  background-color: white

.tab-bar
  position: absolute
  left: 50%
  top: 10px
  background: white
  transform: translateX(-50%)
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25)
  white-space: nowrap
  z-index: 10
  .tab-button
    display: inline-block
  .tab-button:nth-child(n+3)
    margin-left: 8px
  +bpw(xs)
    display: block
    padding: 4px
  +bpw(sm)
    padding: 8px
  +bpw(lg)
    bottom: auto
    left: 50%
    top: 84px
  +bpw(xl)
    padding-left: 16px
    .tab-button
      margin-left: 8px
  .label
    display: none
    +bpw(md)
      display: inline-block
      margin-left: 12px
      margin-right: 12px
    font-family: $font-main
    text-transform: uppercase
    font-weight: bold
    font-size: 12px

.tabs
  margin-top: 64px
  flex: 1
  display: flex
  position: relative

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

