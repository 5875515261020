@import '../lib/core/sass/bp'

body
  background-color: $color-grey-light

h1, h2
  font-family: $font-main

h1
  margin: 0
  margin-top: 16px
  margin-bottom: 16px
  font-size: 20px
  line-height: 25px
  text-transform: uppercase
  +bpw(md)
    margin-top: 16px
    margin-bottom: 22px
    font-size: 30px
    line-height: 45px
  +bpw(xl)
    margin-top: 16px
    margin-bottom: 32px
    font-size: 40px
    line-height: 48px


h1.large
  margin: 0
  margin-top: 16px
  margin-bottom: 16px
  font-size: 35px
  line-height: 55px
  +bpw(md)
    margin-bottom: 26px
    font-size: 55px
    line-height: 85px
  +bpw(xl)
    margin-bottom: 20px
    font-size: 60px
    line-height: 95px
  span
    background-color: $color-yellow
    color: #fff
    display: inline
    line-height: 0px
    padding: 0 1rem
    box-decoration-break: clone



h1.medium
  margin: 0
  margin-top: 16px
  margin-bottom: 16px
  font-size: 13px
  line-height: 22px
  span
    background-color: $color-yellow
    color: #fff
    display: inline
    line-height: 0px
    padding: 0 0.3rem
    box-decoration-break: clone
  +bpw(md)
    margin-bottom: 26px
    font-size: 20px
    line-height: 35px
    span
      padding: 0 0.8rem
  +bpw(xl)
    margin-bottom: 20px
    font-size: 35px
    line-height: 57px
    span
      padding: 0 1rem




.topline
  font-family: $font-main
  color: $color-copy
  padding: 0 0 12px 0
  display: inline-block
  border-radius: 2px
  text-transform: uppercase
  font-size: 12px
  font-weight: 600
  letter-spacing: 0.075em
  +bpw(xl)
    font-size: 14px
    letter-spacing: 0.075em

p
  font-family: $font-secondary
  font-weight: 400
  line-height: 24px
  font-size: 14px
  color: $color-copy

p.negativ
  color: white

.description h2
  text-transform: uppercase
  font-size: 18px
  margin-bottom: 16px

p code
  font-family: $font-secondary !important
  font-size: 0.8em
  line-height: 0px

