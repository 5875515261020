
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  position: relative
  display: block
  margin: 0
  width: 100%
  padding: 8px
  background-color: $color-green
  overflow: auto
  box-sizing: border-box
  &:first-child
    section
      padding-top: 140px
      +bpw(lg)
        padding-top: 200px
      +bpw(xl)
        padding-top: 240px
  &:nth-last-child(2)
    section
      padding-bottom: 140px
      +bpw(lg)
        padding-bottom: 240px

.inner
  border-radius: 8px
  overflow: hidden
  background-color: white

section
  max-width: 960px
  margin: 0 auto
  padding: 0 32px
  +bpw(lg)
    padding: 0 64px
  padding-top: 240px
  h1, h2, h3, p, a
    color: black
  p
    margin: 32px 0

section::v-deep
  strong
    display: inline-block
    margin-top: 16px
    text-transform: uppercase
    font-family: $font-main
