
@import '../../styles/variables'
@import '../../styles/globals'

$gap: 8px
$border-radius: 6px

article
  position: relative
  display: block
  margin: 0
  padding: 0px
  background-color: white
  overflow: auto
  box-sizing: border-box
  &:first-child
    padding-top: 0
    .inner
      padding-top: 150px
      +bpw(md)
        padding-top: 210px
  &:nth-last-child(2)
    padding-bottom: 0

.inner
  position: relative
  overflow: auto
  display: block
  padding-top: 80px
  padding-bottom: 80px
  +bpw(md)
    padding-top: 120px
    padding-bottom: 120px

section
  max-width: 960px
  margin: 0 auto
  text-align: center
  padding: 0 8px
  +bpw(xl)
    padding: 0
    text-align: left
h1
  text-align: center
  padding: 0 32px
  color: $color-bronze-dark
  +bpw(xl)
    padding: 0
    text-align: left
h1
  margin-bottom: 48px

.slider
  position: relative
  width: 100%
  .cw-swiper
    overflow: hidden
.box
  background-color: white
  padding: 16px 0px
  text-align: left
  p
    margin-bottom: 16px
  +bpw(lg)
    max-width: 760px
    margin-top: 10px
    padding: 36px 0px

.video-extern
  margin-top: $gap
  line-height: 0
  video
    width: 100%
    overflow: hidden

.only-cam
  .image-extern
    +bpw(xl)
      margin-left: -20%
      margin-right: -20%
      width: 140%
    +bpw(hg)
      margin-left: -40%
      margin-right: -40%
      width: 180%

.only-video
  .video-extern
    +bpw(xl)
      margin-left: -20%
      margin-right: -20%
      width: 140%
    +bpw(hg)
      margin-left: -40%
      margin-right: -40%
      width: 180%

.only-slider
  .slider
    +bpw(xl)
      margin-left: -20%
      margin-right: -20%
      width: 140%
    +bpw(hg)
      margin-left: -40%
      margin-right: -40%
      width: 180%

.text-slider
  .slider
    +bpw(xl)
      width: 100%
      transform: translateX(-20%)
      &:after
        content: ''
        position: absolute
        right: 0
        bottom: 0
        width: 20%
        height: 100%
        transform: translateX(100%)
        margin-right: - $gap
        background-color: $color-grey-light

.text-video
  .video-extern
    +bpw(xl)
      margin-right: -20%
      width: 100%
      transform: translateX(20%)
      &:after
        content: ''
        position: absolute
        left: 0
        top: 0
        width: 20%
        height: 40%
        border-radius: $border-radius
        transform: translateX(-100%)
        margin-left: - $gap
        background-color: $color-grey-warm

.info
  margin-top: 6px
  position: relative
  text-align: left
  a
    display: inline-block
    vertical-align: middle
    text-decoration: none
    font-family: $font-main
    font-weight: 600
    overflow: hidden
    position: relative
    background-color: $color-red
    border: 1px solid $color-red
    box-sizing: border-box
    border-radius: 4px
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      right: 100%
      height: 100%
      background-color: white
      transition: right 0.45s
    span
      position: relative
      display: block
      color: white
      line-height: 36px
      padding: 0 14px
      text-transform: uppercase
      transition: color 0.45s
    &:hover
      span
        color: $color-red
      &:before
        right: 0
  .box-color
    display: none
    +bpw(lg)
      display: inline-block


.box-color
  display: inline-block
  position: relative
  vertical-align: middle
  width: 86px
  height: 36px
  margin-right: 6px
  border-radius: $border-radius


.box-green
  background-color: $color-grey-warm

.box-lila
  background-color: $color-beige

.box-white
  background-color: $color-bronze

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1
  transition: all 0.7s ease-in-out
  transform: translateY(50px)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0px)

.slider, .box, .video-extern, .info
  transition: all 0.7s ease-in-out
  opacity: 0
  .animate &
    opacity: 1

.box
  transition: all 0.7s ease-in-out 0.5s
.info
  transition: all 0.7s ease-in-out 0.75s
